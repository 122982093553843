import api from './api'

// angular.module('etubeApp').factory('usersApiService', ['$resource', 'API_URL', function ($resource, API_URL) {
//   return $resource(API_URL + '/users/:view', { }, {
//     post: { method: 'POST', isArray: false, params: { includeAuthorization: true } },
//     put: { method: 'PUT', isArray: false, params: { includeAuthorization: true } },
//     getMe: { method: 'GET', isArray: false, params: { includeAuthorization: true, view: 'me' } }
//   })
// }])

export default {
  post: user => api.post('users', user, { headers: { retry: true } }),
  put: user => api.put('users', user),
  getMe: () => api.get('users/me')
}
