import api from './firebase-api'
import apptenderApi from './api'

// angular.module('etubeApp').factory('NotificationsService', ['$resource', function ($resource) {
//   return $resource(config.databaseURL + '/notifications/:uid.json', { uid: '@uid' }, {
//     get: { method: 'GET', isArray: false, params: { includeToken: true, uid: '@uid' } },
//     post: { method: 'POST', isArray: false, params: { includeToken: true, uid: '@uid' } }
//   })
// }])

export default {
  get: () => apptenderApi.get('notifications'),
  post: data => api.post('notifications.json', data).then(result => result.data).catch(error => Promise.error(error)),
  showAlwaysPresent: (text, tag, onclick) => {
    const greeting = new Notification(text, {
      requireInteraction: true,
      tag
    })
    greeting.onclick = () => {
      greeting.close()
      onclick()
    }
    return greeting
  },
  clearNotification: notification => {
    notification.close()
  }
}
