const state = () => ({
  apis: [
    'AIzaSyAxwgu436xtcA97oTvRtzH1tavBq34YIE8', // goreedjo
    'AIzaSyCf0ujR4QI88gaMWNQlDsgdYjwMf-rp1tw', // drumestudio
    'AIzaSyCA8mGMiOr66RpLSXkJeODLIvnd0SMUaJA', // tfiesta.com 1
    'AIzaSyD_6a2xXzFVzOlRwRyjhMOxSB8FjA9_qVY', // tfiesta.com 2
    'AIzaSyDWammo7_oLwMrBw08TFn9qviywrF30nYA', // tfiesta.com 3
    'AIzaSyAsD1LhQ7nStqLNFuPQSVjkVyATu_mELks', // e1825gomez tfiesta
    'AIzaSyAHPDJyVkIK3_C-CHdWbHCEqHJLEsDjBDU' // e1825gomez tfiesta-dev-1
  ],
  currentApi: 'AIzaSyAxwgu436xtcA97oTvRtzH1tavBq34YIE8'
})

const mutations = {
  setCurrentApi: (state, currentApi) => {
    state.currentApi = currentApi
  }
}

const actions = {
  // mark next api as currentApi
  next: ({ commit, state }) => {
    const currentIndex = state.apis.indexOf(state.currentApi)
    if (currentIndex >= 0) {
      const newApi = state.apis[currentIndex + 1]
      commit('setCurrentApi', newApi)
    } else {
      commit('setCurrentApi', null)
    }
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
