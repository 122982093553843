<template lang="pug">
b-message
  div(v-if='allowedForFreeTrial')
    div Necesitas actualizar tu plan para acceder a esta característica. Tu cuenta es aplicable para recibir
      span.bold.ml-1 un mes gratis.
    router-link.mt-2.button.is-primary.is-small(:to='{ name: subscriptionRoute.name, params: { planCode: "standard" } }') Aplicar ahora
  div(v-else)
    | Necesitas actualizar tu plan para acceder a esta característica.
    router-link.ml-1.more-info(:to='{ name: "plans" }' target='_blank') Ver planes
</template>

<script>
import { subscriptionRoute } from '@/router'

export default {
  data () {
    return {
      subscriptionRoute
    }
  },
  computed: {
    user () {
      return this.$store.state.auth.currentUser
    },
    allowedForFreeTrial () {
      return this.user && !this.user.planName
    }
  }
}
</script>

<style lang="scss" scoped>
.more-info {
  white-space: nowrap;
}
</style>
