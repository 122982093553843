let onTrackCallBack = null

function _mapPlanParms (plan, finalPrice) {
  return {
    currency: 'COP',
    value: finalPrice || plan.price,
    items: [{
      item_id: plan.code,
      item_name: plan.name,
      price: finalPrice || plan.price
    }]
  }
}

function onTrackRequest (callback) {
  onTrackCallBack = callback
}

function track (event, params) {
  onTrackCallBack(event, params)
}

function planSelected (plan) {
  track('add_to_cart', _mapPlanParms(plan))
}

function checkoutStarted (plan) {
  track('begin_checkout', _mapPlanParms(plan))
}

function subscribed (transaction) {
  track('purchase', {
    ..._mapPlanParms(transaction.plan, transaction.amount),
    transaction_id: transaction.id
  })
}

export default {
  onTrackRequest,
  track,
  planSelected,
  checkoutStarted,
  subscribed
}
