<template lang="pug">
.color-input-container
  b-colorpicker(
    alpha
    :id='id'
    :required='required'
    :value='rawValue'
    :color-formatter='formatter'
    :color-parser='parser'
    @input='setColor'
  )
</template>

<script>
import Color from 'buefy/src/utils/color'

export default {
  props: {
    id: { type: String },
    value: { type: String },
    required: { type: Boolean }
  },
  data () {
    return {
      rawValue: this.parser(this.value)
    }
  },
  watch: {
    value () {
      this.rawValue = this.value
    }
  },
  methods: {
    setColor ($event) {
      this.$emit('input', $event.toString('hexa'))
    },
    formatter (color) {
      return color.toString('hexa')
    },
    parser (color) {
      return Color.parse(color)
    }
  }
}
</script>
