<template lang="pug">
app-icon.icon(:src='circleWavyWarningIcon' stroke='var(--secondary-color)' fill='var(--secondary-color)')
</template>

<script>
import circleWavyWarningIcon from '@/assets/circle-wavy-warning.svg'

export default {
  data () {
    return { circleWavyWarningIcon }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  height: 16px;
  width: 16px;
  animation: beat 10s infinite;
  transform-origin: center;
}

@keyframes beat {
  0% { transform: scale(1); }
  60% { transform: scale(1); }
  61% { transform: scale(1.4); }
  62% { transform: scale(1); }
  63% { transform: scale(1.4); }
  64% { transform: scale(1); }
  100% { transform: scale(1); }
}
</style>
