export const firebaseConfig = {
  apiKey: process.env.VUE_APP_apiKey,
  authDomain: process.env.VUE_APP_authDomain,
  databaseURL: process.env.VUE_APP_databaseURL,
  projectId: process.env.VUE_APP_projectId,
  storageBucket: process.env.VUE_APP_storageBucket,
  messagingSenderId: process.env.VUE_APP_messagingSenderId,
  appId: process.env.VUE_APP_appId,
  publicKey: process.env.VUE_APP_publicKey
}

export const analyticsConfig = {
  analyticsMainKey: process.env.VUE_APP_analyticsMainKey,
  analyticsAppKey: process.env.VUE_APP_analyticsAppKey
}

export default {
  production: process.env.NODE_ENV === 'production',
  API_URL: process.env.VUE_APP_API_URL,
  WEB_URL: process.env.VUE_APP_WEB_URL,
  SHORTER_URL: process.env.VUE_APP_SHORTER_URL,
  ...firebaseConfig,
  ...analyticsConfig
}
