import { api } from './api'

export default {
  get: businessId => api.get(`/business/items/by-business/${businessId}`),
  getGrouped: (businessId, { includeCategoryImages, includeAssociatesItems } = {}) =>
    api.get(`/business/items/by-business/${businessId}/grouped`, { params: { includeCategoryImages, includeAssociatesItems } }),
  post: (businessId, item) => api.post(`/business/items/${businessId}`, item),
  patch: (businessId, item) => api.patch(`/business/items/${businessId}`, item),
  delete: id => api.delete(`/business/items/${id}`)
}
