import { ToastProgrammatic as Toast, DialogProgrammatic as Dialog } from 'buefy'

// angular.module('etubeApp').factory('toastService', [function ($resource) {
//   return {
//     show: function (message) {
//       alert(message, 10)
//     }
//   }
// }])

export default {
  info: message => Toast.open({
    message: `${message}`,
    duration: 5000,
    position: 'is-bottom'
  }),
  show: message => Toast.open({
    message: `${message}`,
    duration: 5000,
    position: 'is-bottom'
  }),
  confirm: (message, confirmText) => new Promise(resolve => {
    Dialog.confirm({
      // title: 'Deleting account',
      message,
      cancelText: 'Cancelar',
      confirmText: confirmText || 'Continuar',
      // type: 'is-danger',
      hasIcon: true,
      onConfirm: () => resolve(true),
      onCancel: () => resolve(false)
    })
  }),
  confirmNative: message => new Promise(resolve => {
    resolve(confirm(message))
  }),
  prompt: (title, placeholder) => new Promise(resolve => {
    Dialog.prompt({
      message: title,
      inputAttrs: {
        type: 'text',
        placeholder
        // value: 'Hello moon!'
      },
      confirmText: 'Aceptar',
      trapFocus: true,
      onConfirm: (value) => {
        resolve(value)
      },
      onCancel: () => resolve(null)
    })
  }),
  error: message => Toast.open({
    message: `${message}`,
    type: 'is-danger',
    duration: 5000,
    position: 'is-bottom'
  }),
  warn: message => Toast.open({
    message: `${message}`,
    type: 'is-warning',
    duration: 5000,
    position: 'is-bottom'
  }),
  console: (message, ...optional) => {
    // eslint-disable-next-line no-console
    console.error(message, optional)
  }
}
