<template lang="pug">
div(v-if='user && daysToExpire && daysToExpire <= 5 || closed')
  b-message.main-alert-handle.mb-3(title='Recuerda renovar tu plan'  aria-close-label='Cerrar mensaje' @close='closed=true') {{ expireMessage }},  el {{ user.planEnd | date }} - Renueva tu plan  {{ planUser }}
    router-link.ml-1(:to='{ name: subscriptionRoute.name, params: { planCode: user.plan.code } }' v-if='planUser') Renovar
    router-link.ml-1(:to='{ name: plansRoute.name }' v-if='!planUser') Renovar
</template>
<script>
import { plansRoute, subscriptionRoute } from '@/router'

export default {
  data () {
    return {
      plansRoute,
      subscriptionRoute,
      closed: false
    }
  },
  computed: {
    planUser () {
      return this.user.plan.name !== 'Gratis' ? this.user.plan.name : ''
    },
    daysToExpire () {
      return this.$store.getters['auth/daysToExpire']
    },
    expireMessage () {
      return this.$store.getters['auth/expireMessage']
    },
    user () {
      return this.$store.state.auth.currentUser
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables/colors';
.main-alert-handle {
  color: $secondary-color;
  border: 1px solid;
}

</style>
