export default {
  path: '/business',
  name: 'MyBusiness',
  meta: { requiresAuth: true },
  redirect: '/business/profile',
  component: () => import(/* webpackChunkName: "business" */ '@/views/my-business/Index.vue'),
  children: [
    {
      path: 'profile',
      name: 'myBusinessProfile',
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "my-business-profile" */ '@/views/my-business/Profile.vue')
    },
    {
      path: 'digital-menu',
      name: 'myBusinessDigitalMenu',
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "my-business-digital-menu" */ '@/views/my-business/DigitalMenu.vue')
    },
    {
      path: 'widgets',
      name: 'myBusinessWidgets',
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "my-business-widgets" */ '@/views/my-business/Widgets.vue')
    },
    {
      path: 'clients',
      name: 'myBusinessClients',
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "my-business-clients" */ '@/views/my-business/Clients.vue')
    },
    {
      path: 'clients/create',
      name: 'myBusinessNewClient',
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "my-business-client" */ '@/views/my-business/Client.vue')
    },
    {
      path: 'clients/:id',
      name: 'myBusinessClient',
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "my-business-client" */ '@/views/my-business/Client.vue')
    },
    {
      path: 'users',
      name: 'myBusinessUsers',
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "my-business-users" */ '@/views/my-business/Users.vue')
    },
    {
      path: 'design',
      name: 'myBusinessDesign',
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "my-business-design" */ '@/views/my-business/Design.vue')
    },
    {
      path: 'menu',
      name: 'myBusinessMenu',
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "my-business-menu" */ '@/views/my-business/Menu.vue')
    },
    {
      path: 'tables',
      name: 'myBusinessTables',
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "my-business-tables" */ '@/views/my-business/Tables.vue')
    },
    {
      path: 'configs',
      name: 'myBusinessConfigs',
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "my-business-configs" */ '@/views/my-business/Configs.vue')
    },
    {
      path: 'menuReports',
      name: 'myBusinessReports',
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "my-business-reports" */ '@/views/my-business/MenuReport.vue')
    },
    {
      path: 'ReportSalesByDate',
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "mob-ReportSalesByDate" */ '@/views/app/ReportSalesByDate.vue')
    }
  ]
}
