<template lang="pug">
span.svg(ref='span')
</template>

<script>
import axios from 'axios'

const putAttribute = (element, attrName, value) => {
  if (value) {
    element.setAttribute(attrName, value)
  }
}

export default {
  props: {
    src: { type: String },
    stroke: { type: String },
    fill: { type: String }
  },
  async created () {
    this.render()
  },
  watch: {
    src () {
      this.render()
    },
    stroke () {
      this.updateColors()
    },
    fill () {
      this.updateColors()
    }
  },
  methods: {
    async render () {
      const response = await axios.get(this.src)
      this.$refs.span.innerHTML = response.data
      this.updateColors()
    },
    updateColors () {
      const svg = this.$refs.span.querySelector('svg')
      if (svg) {
        putAttribute(svg, 'stroke', this.stroke)
        putAttribute(svg, 'fill', this.fill)
        svg.querySelectorAll('svg path').forEach(path => {
          putAttribute(path, 'stroke', this.stroke)
          putAttribute(path, 'fill', this.fill)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.svg {
  display: inline-flex;
}
</style>
