const state = () => ({
  menuOpened: null
})

const mutations = {
  setMenuOpened: (state, opened) => {
    state.menuOpened = opened
  }
}

const actions = {
  toggleMenu: ({ commit, state }) => {
    commit('setMenuOpened', !state.menuOpened)
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
