import businessItemsService from '@/services/business-items.service'

const state = () => ({
  categories: [],
  categoriesParams: {},
  searchCategories: [],
  error: false,
  loading: false
})

const mutations = {
  setError: (state, error) => {
    state.error = error
  },
  setLoading: (state, loading) => {
    state.loading = loading
  },
  setCategories: (state, { categories, params }) => {
    state.categories = categories
    state.categoriesParams = params
  },
  setSearchCategories: (state, categories) => {
    state.searchCategories = categories
  }
}

const actions = {
  getCategories: async ({ rootState, state, commit }, { includeAssociatesItems } = {}) => {
    const currentBusiness = rootState.appBusiness.business
    const params = { includeAssociatesItems }
    const paramsAreDifferent = JSON.stringify(params) !== JSON.stringify(state.categoriesParams)
    if (currentBusiness.id && (!state.categories.length || paramsAreDifferent)) {
      commit('setLoading', true)
      commit('setError', false)
      const result = await businessItemsService.getGrouped(currentBusiness.id, params)
      if (result) {
        result.forEach(category => {
          category.items = category.items.filter(item => !item.hide)
        })
        commit('setCategories', { categories: result, params })
      } else {
        commit('setError', true)
      }
      commit('setLoading', false)
    }
  },
  getSearchCategories: async ({ rootState, state, commit }, { includeAssociatesItems } = {}) => {
    const currentBusiness = rootState.appBusiness.business
    const params = { includeAssociatesItems }
    const paramsAreDifferent = JSON.stringify(params) !== JSON.stringify(state.categoriesParams)
    if (!paramsAreDifferent && state.categories.length) {
      commit('setSearchCategories', state.categories)
    } else if (currentBusiness.id && (!state.searchCategories.length || paramsAreDifferent)) {
      commit('setLoading', true)
      commit('setError', false)
      const result = await businessItemsService.getGrouped(currentBusiness.id, params)
      if (result) {
        result.forEach(category => {
          category.items = category.items.filter(item => !item.hide)
        })
        commit('setSearchCategories', result)
      } else {
        commit('setError', true)
      }
      commit('setLoading', false)
    }
  }
}

const getters = {
  searchItems: state => {
    const items = state.searchCategories.reduce((items, category) => {
      items.push(...category.items.map(item => ({
        businessName: category.business.name,
        ...item
      })))
      return items
    }, [])
    return items
  },
  categoryOrBusinessGroups: state => {
    const itemsGroupedByBusinesses = state.categories.reduce((businesses, category) => {
      let business = businesses.find(b => b.id === category.businessId)
      if (!business) {
        business = { id: category.businessId, name: category.business.name, items: [] }
        businesses.push(business)
      }
      business.items.push(...category.items)
      return businesses
    }, [])
    return itemsGroupedByBusinesses
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
