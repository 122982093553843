<template lang="pug">
#app
  router-view
</template>

<script>
import trackingService from '@/services/tracking.service'
import authService from '@/services/auth.service'

export default {
  created () {
    const variables = {
      '$text-color': 'white',
      '$accent-brand': '#DF1254',
      '$accent-brand-contrast': '#FFF',
      '$secondary-color': '#FFA859',
      '$primary-brand-bck-01': '#121324',
      '$primary-brand-bck-02': '#191B33',
      '$primary-brand-bck-03': '#272A45'
    }
    const root = document.documentElement

    Object.keys(variables).reduce((memory, key) => {
      const variable = `--${key.substring(1)}`
      const value = variables[key]
      root.style.setProperty(variable, value)
    }, {})

    trackingService.onTrackRequest((action, params) => {
      this.$gtag.event(action, params)
    })

    authService.addOnUserIdentified(googleUser => {
      this.$gtag.set({ user_id: googleUser.uid })
    })
  }
}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  flex: 1;
}

</style>
