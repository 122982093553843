import { api } from './api'

const sessionCodeLocalName = 'codeSession'

export default {
  post: session => api.post('/business/sessions', session),
  setLocalCode: code => {
    if (code) {
      localStorage.setItem(sessionCodeLocalName, code)
    } else {
      localStorage.removeItem(sessionCodeLocalName)
    }
  },
  getLocalCode: () => {
    return localStorage.getItem(sessionCodeLocalName)
  }
}
