import businessService from '@/services/business.service'
import { onBusinessSelected } from '@/services/global-events.service'

const state = () => ({
  business: {},
  allBusiness: [],
  error: false,
  loading: false
})

const mutations = {
  setError: (state, error) => {
    state.error = error
  },
  setLoading: (state, loading) => {
    state.loading = loading
  },
  setAllBusiness: (state, allBusiness) => {
    state.allBusiness = allBusiness
    const businessSelectedId = localStorage.getItem('businessSelected')
    if (allBusiness.length && businessSelectedId) {
      const existsBusiness = allBusiness.find(b => b.id === +businessSelectedId)
      state.business = existsBusiness || allBusiness[0]
    } else state.business = allBusiness.length ? allBusiness[0] : {}
  },
  setCurrentBusiness: (state, business) => {
    state.business = business || {}
    if (business.id) {
      localStorage.setItem('businessSelected', business.id)
    }
  }
}

const actions = {
  loadAllMyBusiness: async ({ commit }) => {
    await commit('setLoading', true)
    const result = await businessService.getAllMyBusiness()
    if (result) {
      await commit('setAllBusiness', result)
      await commit('setError', false)
    } else {
      await commit('setAllBusiness', [])
      await commit('setError', true)
    }
    await commit('setLoading', false)
  },
  selectBusiness: async ({ commit }, business) => {
    await commit('setCurrentBusiness', business)
    onBusinessSelected.invoke(business)
  }
}

const getters = {
  currentOrderQuantity: state => {
    return !!state.businessOrderDetails &&
      state.businessOrderDetails.reduce((sum, item) => sum + item.requestQuantity, 0)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
