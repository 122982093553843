<template lang="pug">
.highlight-box.content-box
  .is-flex.is-justify-content-space-between
    div
      p.bold {{ label }}
      p.text-small {{ placeholder }}
    b-switch(v-model='internalValue' @input='changed')
</template>

<script>
export default {
  props: {
    value: { type: Boolean },
    label: { type: String },
    placeholder: { type: String }
  },
  data () {
    return {
      internalValue: this.value
    }
  },
  watch: {
    value () {
      this.internalValue = this.value
    }
  },
  methods: {
    changed () {
      this.$emit('input', this.internalValue)
    }
  }
}
</script>
