<template lang="pug">
.breadcrumb-container
  template(v-for='(item, index) in items')
    span(v-if='index === items.length - 1') {{ item.display }}
    router-link.breadcrumb-item(v-else :to='item.to') {{ item.display }}
    span.breadcrumb-item(v-if='index < items.length - 1') {{ splitter }}
</template>

<script>
export default {
  props: {
    items: { type: Array, default: () => [] },
    splitter: { type: String, default: '/' }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables/sizes';

.breadcrumb-container {
  display: flex;
  align-items: center;
}

.breadcrumb-item {
  margin-right: $spacing-constant;
}
</style>
