export const alertTypes = {
  order: 'order',
  music: 'music',
  screen: 'screen',
  waiter: 'waiter',
  invoice: 'invoice'
}

export const alertStatus = {
  created: 1,
  requested: 2,
  accepted: 3
}

export const alertStatusDescriptions = {
  [alertStatus.created]: 'Creado',
  [alertStatus.requested]: 'Solicitado',
  [alertStatus.accepted]: 'Aceptado'
}

export const alertTypeDescriptions = {
  [alertTypes.music]: 'Música',
  [alertTypes.screen]: 'Pantalla',
  [alertTypes.waiter]: 'Llamada al mesero',
  [alertTypes.invoice]: 'Petición de la cuenta'
}
