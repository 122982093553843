import { differenceInDays, format } from 'date-fns'
import { isPremiumActive } from '@/services/premium.service'
import usersService from '@/services/users.service'

const state = () => ({
  currentUser: null
})

const mutations = {
  setAuth: (state, currentUser) => {
    state.currentUser = currentUser
  }
}

const actions = {
  login: ({ commit }, user) => {
    commit('setAuth', user)
  },
  refresh: async ({ commit }) => {
    const result = await usersService.getMe()
    if (result) {
      await commit('setAuth', result)
    }
  },
  logout: ({ commit }) => {
    commit('setAuth', null)
  }
}

const getters = {
  isAuthenticated: state => !!state.currentUser,
  isPremium: state => {
    return isPremiumActive(state.currentUser)
  },
  flags: state => {
    const user = state.currentUser
    if (!user || !user.plan || !user.plan.flags) return {}
    return user.plan.flags
  },
  daysToExpire: state => {
    const user = state.currentUser
    if (!user.planEnd) return null
    const today = new Date(format(new Date(), 'yyyy-MM-dd') + 'T00:00:00')
    return differenceInDays(new Date(user.planEnd), today)
  },
  expireMessage: (state, getters) => {
    if (getters.daysToExpire === 0) return 'Tu suscripción expira hoy'
    if (getters.daysToExpire === 1) return 'Tu suscripción expira mañana'
    if (getters.daysToExpire < 0) return 'Tu suscripción ya expiró'
    return `Tu suscripción expira en ${getters.daysToExpire} días`
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
