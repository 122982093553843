const currencyFormatter = new Intl.NumberFormat('es-CO', {
  style: 'currency',
  currency: 'COP',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
})

export default function (value) {
  if (!value && value !== false && value !== 0) return ''
  return currencyFormatter.format(value)
}
