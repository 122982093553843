import { alertStatus } from '@/constants/alert-types'
import businessAlertsService from '@/services/business-alerts.service'

const state = () => ({
  alerts: [],
  isLoading: false,
  haveNewAlerts: false
})

const mutations = {
  setAlerts: (state, alerts) => {
    state.alerts = alerts
  },
  setIsLoading: (state, isLoading) => {
    state.isLoading = isLoading
  },
  setHaveNewAlerts: (state, haveNewAlerts) => {
    state.haveNewAlerts = haveNewAlerts
  }
}

const actions = {
  loadAlerts: async ({ commit, state }, businessId) => {
    await commit('setIsLoading', true)
    const result = await businessAlertsService.getByMyBusiness(businessId)
    if (result) {
      const newAlerts = result
      const haveNewAlerts = newAlerts.some(a1 => !state.alerts.some(a2 => a1.id === a2.id))
      await commit('setAlerts', result)
      if (haveNewAlerts && !state.haveNewAlerts) {
        await commit('setHaveNewAlerts', haveNewAlerts)
      }
    }
    await commit('setIsLoading', false)
  },
  refreshAlerts: async ({ commit, state }, businessId) => {
    const result = await businessAlertsService.getByMyBusiness(businessId)
    if (result) {
      const newAlerts = result
      const haveNewAlerts = newAlerts.some(a1 => !state.alerts.some(a2 => a1.id === a2.id))
      await commit('setAlerts', result)
      if (haveNewAlerts && !state.haveNewAlerts) {
        await commit('setHaveNewAlerts', haveNewAlerts)
      }
    }
  },
  removeAlert: async ({ commit, state }, alert) => {
    await commit('setIsLoading', true)
    const result = await businessAlertsService.remove(alert.id)
    if (result) {
      const newAlerts = state.alerts.filter(a => a.id !== alert.id)
      await commit('setAlerts', newAlerts)
    }
    await commit('setIsLoading', false)
  },
  acceptAlert: async ({ commit }, alert) => {
    await commit('setIsLoading', true)
    const newAlert = { ...alert, status: alertStatus.accepted }
    const result = await businessAlertsService.post(newAlert)
    if (result) {
      alert.status = newAlert.status
    }
    await commit('setIsLoading', false)
  },
  markNewAlertsAsReaded: async ({ commit }) => {
    await commit('setHaveNewAlerts', false)
  }
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
