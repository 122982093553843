import mediaStatusService from '@/services/media-status.service'

const state = () => ({
  currentStatusMap: {}
})

const mutations = {
  setCurrentStatusMap: (state, mediaStatus) => {
    state.currentStatusMap = mediaStatus
  }
}

const actions = {
  mediaStatusUpdated: async ({ commit, state }, { videoId, status }) => {
    const newStatus = { ...state.currentStatusMap, [videoId]: status }
    await commit('setCurrentStatusMap', newStatus)
  },
  checkMultipleMediaStatus: async ({ commit, state }, videoIds) => {
    const result = await mediaStatusService.getMultiple(videoIds)
    if (result) {
      const newStatus = { ...state.currentStatusMap }
      result.map(mediaStatus => {
        newStatus[mediaStatus.videoId] = mediaStatus.status
      })
      await commit('setCurrentStatusMap', newStatus)
    }
  }
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
