import myBusinessRoute from './myBusiness'
import * as playerControlRoutes from './playerControl'
import { playerControlRoute } from './playerControl/index'

const homeRoute = {
  ...playerControlRoute
}

export const loginRoute = {
  path: '/login',
  name: 'login',
  component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
}

export const registerRoute = {
  path: '/register',
  name: 'register',
  component: () => import(/* webpackChunkName: "register" */ '@/views/Register.vue')
}

export const accountCreatedRoute = {
  path: '/account-created',
  name: 'account-created',
  meta: { requiresAuth: true },
  component: () => import(/* webpackChunkName: "account-created" */ '@/views/AccountCreated.vue')
}

export const errorRoute = {
  path: '/error',
  name: 'error',
  meta: { skipAuthValidation: true },
  component: () => import(/* webpackChunkName: "error" */ '@/views/Error.vue')
}

export default {
  myBusinessRoute,
  homeRoute,
  loginRoute,
  accountCreatedRoute,
  ...playerControlRoutes,
  registerRoute,
  errorRoute
}
