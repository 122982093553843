import axios from 'axios'
import { firebaseConfig } from '@/configuration'

const instance = axios.create({
  baseURL: firebaseConfig.databaseURL
})

instance.interceptors.request.use((config) => {
  if (!config.params) {
    config.params = {}
  }
  config.params.auth = localStorage.token
  return config
})

// instance.interceptors.response.use(response => response, function (error) {
//   if (error.response && error.response.status === 400) {
//     if (error.response.data.error === 'token is expired') {
//       expiredSessionCallbacks.forEach(callback => callback())
//     }
//   } else if (error.message && error.message.toLowerCase().indexOf('network') >= 0) {
//     error.isNetworkError = true
//   }
//   return Promise.reject(error)
// })
export default instance
