<template lang="pug">
.text-input-container(:class='{ [`no-label`]: !label }')
  select.input-default(
    :id='id'
    :class='{ filled: !!rawValue, highlighted }'
    ref='input'
    v-model='rawValue'
    :maxlength='maxlength'
    :required='required'
    :autocomplete='autocomplete'
    @focus='focused = true'
    @blur='focused = false'
    @input='rawValueChanged'
  )
    option(v-if='placeholder' value) {{ placeholder }}
    option(v-for='item in items' :value='item.id') {{ item.name }}
  label.input-label(:for='id') {{ label }}
  .input-footer(v-if='placeholderAlways && placeholder')
    p.input-placeholder {{ placeholder }}
</template>ß

<script>
export default {
  props: {
    id: { type: String },
    label: { type: String },
    placeholder: { type: String },
    placeholderAlways: { type: Boolean },
    maxlength: { type: Number },
    value: { type: [String, Number] },
    highlighted: { type: Boolean },
    required: { type: Boolean },
    autocomplete: { type: String },
    items: { type: Array, default: () => [] }
  },
  data () {
    return {
      rawValue: this.value,
      focused: false
    }
  },
  watch: {
    value () {
      this.rawValue = this.value
    }
  },
  methods: {
    rawValueChanged (event) {
      this.$emit('input', event.target.value)
    },
    focus () {
      this.$refs.input.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/mixins/text';
@import '~@/scss/mixins/inputs';
@import '~@/scss/variables/colors';
@import '~@/scss/variables/sizes';

.text-input-container {
  @include input-container();

  position: relative;
  padding-top: 19px;

  &.no-label {
    padding-top: 0;
  }

  /* Chrome, Safari, Edge, Opera */
  .input-default::-webkit-outer-spin-button,
  .input-default::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  .input-default[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
}

.input-label {
  position: absolute;
  top: 34px;
  left: $spacing-constant * 2;
  transition: transform 100ms ease-in, color 100ms ease-in, font-size 100ms ease-in;
}

.input-footer {
  margin: 0 $spacing-constant * 2;
}

.input-default {
  @include input-default();

  &:focus {
    border-color: var(--accent-brand);
    outline: none;
    caret-color: var(--accent-brand);

    & + label {
      color: var(--accent-brand);
    }
  }
}
</style>
