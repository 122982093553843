import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth.module'
import yt from './modules/yt.module'
import appBusiness from './modules/app-business.module'
import appBusinessItems from './modules/app-business-items.module'
import myBusiness from './modules/my-business.module'
import playList from './modules/play-list.module'
import connect from './modules/connect.module'
import broadcast from './modules/broadcast.module'
import notifications from './modules/notifications.module'
import ui from './modules/ui.module'
import mediaStatus from './modules/media-status.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    yt,
    appBusiness,
    appBusinessItems,
    myBusiness,
    playList,
    connect,
    notifications,
    ui,
    broadcast,
    mediaStatus
  }
})
