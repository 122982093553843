/* eslint-disable no-console */
import { initializeApp } from 'firebase/app'
import { getAuth, signOut, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'

import store from '../store'
import { firebaseConfig } from '@/configuration'
import usersApiService from './users.service'
import { setToken, clearToken } from './api'

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const provider = new GoogleAuthProvider()
auth.useDeviceLanguage()
// let messaging = null

const parseAndGetToken = async (forceTokenUpdate) => {
  const googleUser = auth.currentUser
  const { displayName, email, emailVerified, photoURL, uid, phoneNumber, providerData } = googleUser
  const accessToken = await googleUser.getIdToken((forceTokenUpdate === true))
  const userData = { displayName, email, emailVerified, phoneNumber, photoURL, uid, providerData, accessToken }
  setToken(accessToken)
  programUpdateToken()
  return userData
}

let timeoutToUpdateToken = null
const programUpdateToken = () => {
  if (timeoutToUpdateToken) {
    clearInterval(timeoutToUpdateToken)
  }
  timeoutToUpdateToken = setInterval(refreshToken, 1000 * 60 * 50) // refrescar token cada 50 min
}

const refreshToken = async () => {
  return await parseAndGetToken(true)
}

const tryGetCurrentUserAndSetToken = (getOnlyGoogleUser) => new Promise((resolve, reject) => {
  const parseAndGetDbUser = async (googleUser) => {
    if (!googleUser) {
      resolve(null)
      return
    }
    onUserIdentified(googleUser)
    try {
      const parsedUser = await parseAndGetToken()
      const dbUser = await usersApiService.getMe()
      if (dbUser && dbUser.id) {
        const currentUser = { ...dbUser, ...parsedUser }
        await store.dispatch('auth/login', currentUser)
        resolve(currentUser)
      } else if (getOnlyGoogleUser) {
        resolve(parsedUser)
        return
      } else resolve(null)
    } catch (error) {
      console.error('Error determinando sesión', error)
      store.dispatch('auth/logout')
      reject(error)
    }
  }
  if (auth.currentUser) {
    parseAndGetDbUser(auth.currentUser)
  } else {
    auth.onAuthStateChanged(googleUser => parseAndGetDbUser(googleUser), error => {
      console.error('Error determinando sesión', error)
      store.dispatch('auth/logout')
      reject(error)
    })
  }
})

const loginWithGoogle = async () => {
  try {
    await signInWithPopup(auth, provider)
    const user = await tryGetCurrentUserAndSetToken()
    return user
  } catch (error) {
    console.error(error)
    throw new Error('Error iniciando sesión con Google, por favor intenta de nuevo.')
  }
}

const closeSession = async () => {
  const result = await signOut(auth)
  clearToken()
  store.dispatch('auth/logout')
  return result
}

const onUserIdentifiedCallbacks = []

const onUserIdentified = user => {
  onUserIdentifiedCallbacks.forEach(callback => callback(user))
}

const addOnUserIdentified = callback => {
  onUserIdentifiedCallbacks.push(callback)
  return {
    unsubscribe () {
      const index = onUserIdentifiedCallbacks.indexOf(callback)
      if (index >= 0) {
        onUserIdentifiedCallbacks.splice(index, 1)
      }
    }
  }
}

export default {
  firebaseApp: app,
  loginWithGoogle,
  tryGetCurrentUserAndSetToken,
  signOut: closeSession,
  addOnUserIdentified
}
