<template lang="pug">
router-link.button.is-small.to-premium-button(:to='to' :target='external ? "_blank" : null')
  slot
</template>

<script>
export default {
  props: {
    to: { type: Object },
    external: { type: Boolean }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables/colors';

$default-transform: rotate(35deg) translate(0, -80px);
$to-transform: rotate(35deg) translate(213px, -100px);

.to-premium-button {
  background: none;
  border: solid 1px $secondary-color;
  color: $secondary-color;
  transition: box-shadow 0.3s ease-in-out;
  border: 1px solid;
  overflow: hidden;
  position: relative;

  &::after {
    background: #fff;
    content: "";
    height: 155px;
    left: -75px;
    opacity: 0.2;
    position: absolute;
    top: -50px;
    animation: brightnes 15s infinite;
    transform: $default-transform;
    width: 50px;
    z-index: -10;
  }

  &:hover {
    box-shadow: 1px 1px 10px $secondary-color;
  }
}

.icon {
  height: 16px;
  width: 16px;
  animation: beat 10s infinite;
  transform-origin: center;
}

@keyframes brightnes {
  0% { transform: $default-transform; }
  60% { transform: $default-transform; }
  63% { transform: $to-transform }
  66% { transform: $default-transform; }
  100% { transform: $default-transform; }
}
</style>
