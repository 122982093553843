
import { formatDistanceStrict as fds, differenceInMilliseconds, format } from 'date-fns'
import { es } from 'date-fns/locale'

const currentLocale = 'es'
const locales = { es }

export const formatDistanceStrict = (value, reduce) => {
  if (reduce) {
    const seconds = differenceInMilliseconds(new Date(value), new Date()) * -1 / 1000
    if (seconds < 60) return Math.round(seconds) + 's'
    const minutes = seconds / 60
    if (minutes < 60) return Math.round(minutes) + 'm'
    const hours = minutes / 60
    return Math.round(hours) + 'h'
  }
  return fds(new Date(value), new Date(), { locale: locales[currentLocale] })
}
export const dateTime = value => format(new Date(value), 'dd MMM yyyy, HH:mm:ss')
export const date = value => format(new Date(value), 'dd MMM yyyy')
