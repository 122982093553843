import Vue from 'vue'

const newEvent = (nextTickNeeded) => ({
  listeners: [],
  add: function (callback) {
    this.listeners.push(callback)
    return callback
  },
  invoke: async function (params1, params2) {
    if (nextTickNeeded) {
      await Vue.nextTick()
    }
    this.listeners.forEach(fn => fn(params1, params2))
  },
  remove: function (callback) {
    const index = this.listeners.indexOf(callback)
    if (index >= 0) {
      this.listeners.splice(index, 1)
    }
  }
})

export const onAddToList = newEvent()
export const onVideoAddedToList = newEvent()
export const onListAdded = newEvent()
export const onShowModalTexts = newEvent()
export const onBusinessSelected = newEvent()
export const onBusinessSaved = newEvent()
export const onScrollToVideoRequested = newEvent(true)
export const onPlayerStateChanged = newEvent()
export const onBroadcastMessageReceived = newEvent()
