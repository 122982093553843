import * as communicator from '@/libs/hermes'
import { moveArray, moveArrayItem } from '@/helpers/array.helper'
import { onPlayerStateChanged } from '@/services/global-events.service'
import { playerStatuses } from '@/constants/player-statuses'

const shufflePlaylistMirror = (list) => {
  let currentIndex = list.length
  let temporaryValue; let randomIndex
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1
    // And swap it with the current element.
    temporaryValue = list[currentIndex]
    list[currentIndex] = list[randomIndex]
    list[randomIndex] = temporaryValue
  }
  return list
}

const addToList = (video, currentList, current, addAfterCurrent) => {
  const { videoId } = video
  const currentPosition = currentList.findIndex(v => v.videoId === videoId)
  if (currentPosition >= 0) {
    // TODO: This assign is not being updated
    Object.assign(currentList[currentPosition], video)
    const currentIndex = current ? currentList.findIndex(v => v.videoId === current.videoId) : -1
    const newPosition = addAfterCurrent ? currentIndex + 1 : currentList.length
    const newCurrentList = moveArray(currentList, currentPosition, newPosition)
    return newCurrentList
  } else {
    let currentIndex = current ? currentList.findIndex(v => v.videoId === current.videoId) : -1
    currentIndex = addAfterCurrent ? (currentIndex === -1 ? 0 : currentIndex + 1) : currentList.length
    const newCurrentList = [...currentList]
    newCurrentList.splice(currentIndex, 0, video)
    return newCurrentList
  }
}

const addListOnListCurrent = (videoListNew, currentList, current, index) => {
  // TODO: Edward, la variable current no se está usando
  const newCurrentList = [...currentList]
  newCurrentList.splice(index, 0, ...videoListNew)
  const uniqueNewCurrentList = []
  const uniqueIds = {}
  newCurrentList.forEach(item => {
    if (!uniqueIds[item.videoId] && item.thumbnailDefault !== null) {
      uniqueIds[item.videoId] = true
      uniqueNewCurrentList.push(item)
    }
  })
  return uniqueNewCurrentList
}

const state = () => ({
  current: localStorage.current ? JSON.parse(localStorage.current) : null,
  currentList: localStorage.currentList ? JSON.parse(localStorage.currentList) : [],
  currentListReference: localStorage.currentListReference ? JSON.parse(localStorage.currentListReference) : null,
  playlistEdited: localStorage.playlistEdited === 'true',
  nextList: localStorage.nextList ? JSON.parse(localStorage.nextList) : [],
  currentPreviewId: null,
  isRepeating: false,
  extensionVersion: null,
  playerStatus: playerStatuses.stopped
})

const mutations = {
  setCurrentList: (state, currentList) => {
    state.currentList = currentList
    localStorage.currentList = JSON.stringify(currentList)
  },
  setNextList: (state, nextList) => {
    state.nextList = nextList
    localStorage.nextList = JSON.stringify(nextList)
  },
  setCurrentAndNextLists: (state, { currentList, nextList }) => {
    state.currentList = currentList
    state.nextList = nextList
    localStorage.currentList = JSON.stringify(currentList)
    localStorage.nextList = JSON.stringify(nextList)
  },
  setPlayerStatus: (state, status) => {
    state.playerStatus = status
  },
  setCurrentListReference: (state, currentListReference) => {
    state.currentListReference = currentListReference
    if (currentListReference) {
      localStorage.currentListReference = JSON.stringify(currentListReference)
    } else {
      localStorage.removeItem('currentListReference')
    }
  },
  setPlaylistEdited: (state, playlistEdited) => {
    state.playlistEdited = playlistEdited
    localStorage.playlistEdited = state.playlistEdited
  },
  setCurrent: (state, video) => {
    state.current = video
    localStorage.currentVideoId = video.videoId
    localStorage.current = JSON.stringify(video)
  },
  setCurrentPreviewId: (state, currentPreviewId) => {
    state.currentPreviewId = currentPreviewId
  },
  setIsRepeating: (state, isRepeating) => {
    state.isRepeating = isRepeating
  },
  setExtensionVersion: (state, extensionVersion) => {
    state.extensionVersion = extensionVersion
  }
}

const actions = {
  playerStatusUpdated: async ({ commit }, newStatus) => {
    await commit('setPlayerStatus', newStatus)
    onPlayerStateChanged.invoke()
  },
  loadPlayList: async ({ commit }, { currentList, nextList, listReference }) => {
    await commit('setCurrentAndNextLists', { currentList, nextList })
    if (listReference) {
      const { id, name } = listReference
      await commit('setCurrentListReference', { id, name })
    } else {
      await commit('setCurrentListReference', null)
    }
    await commit('setPlaylistEdited', false)
  },
  loadCurrentListReference: async ({ commit }, listReference) => {
    const { id, name } = listReference
    await commit('setCurrentListReference', { id, name })
    await commit('setPlaylistEdited', false)
  },
  updateLists: async ({ commit }, { currentList, nextList }) => {
    await commit('setCurrentAndNextLists', { currentList, nextList })
    await commit('setPlaylistEdited', true)
  },
  currentPlaylistSaved: async ({ commit }) => {
    await commit('setPlaylistEdited', false)
  },
  play: async ({ commit, rootState, rootGetters }, video) => {
    const { videoId } = video
    const flags = (rootState.auth.currentUser?.plan?.flags || {})
    const isPremium = (rootGetters['auth/isPremium'])
    communicator.send('play_now', { videoId, flags, isPremium })
    await commit('setCurrent', video)
    localStorage.currentTitle = video.title
    onPlayerStateChanged.invoke()
  },
  addNext: async ({ commit, state }, video) => {
    const newCurrentList = addToList(video, state.currentList, state.current, true)
    const newNextList = state.nextList.filter(v => v.videoId !== video.videoId)
    if (newNextList.length !== state.nextList.length) {
      await commit('setCurrentAndNextLists', { currentList: newCurrentList, nextList: newNextList })
    } else {
      await commit('setCurrentList', newCurrentList)
    }
    await commit('setPlaylistEdited', true)
  },
  addEnd: async ({ commit, state }, video) => {
    const newCurrentList = addToList(video, state.currentList, state.current, false)
    const newNextList = state.nextList.filter(v => v.videoId !== video.videoId)
    if (newNextList.length !== state.nextList.length) {
      await commit('setCurrentAndNextLists', { currentList: newCurrentList, nextList: newNextList })
    } else {
      await commit('setCurrentList', newCurrentList)
    }
    await commit('setPlaylistEdited', true)
  },
  showPreview: async ({ commit }, videoId) => {
    await commit('setCurrentPreviewId', videoId)
  },
  remove: async ({ commit, state }, video) => {
    const newCurrentList = state.currentList.filter(v => v.videoId !== video.videoId)
    const newNextList = state.nextList.filter(v => v.videoId !== video.videoId)

    const needToUpdateCurrentList = newCurrentList.length !== state.currentList.length
    const needToUpdateNextList = newNextList.length !== state.nextList.length

    if (needToUpdateCurrentList && needToUpdateNextList) {
      await commit('setCurrentAndNextLists', { currentList: newCurrentList, nextList: newNextList })
    } else {
      if (needToUpdateCurrentList) {
        await commit('setCurrentList', newCurrentList)
      }
      if (needToUpdateNextList) {
        await commit('setNextList', newNextList)
      }
    }
    await commit('setPlaylistEdited', true)
  },
  backward: async ({ state, dispatch }) => {
    const { current, currentList } = state
    if (!current) return
    const currentIndex = currentList.findIndex(v => v.videoId === current.videoId)
    if (currentIndex > 0) {
      const newPosition = currentIndex - 1
      dispatch('play', currentList[newPosition])
    }
  },
  forward: async ({ state, dispatch, commit }) => {
    const { current, currentList, nextList, isRepeating } = state
    if (!current) return
    const currentIndex = currentList.findIndex(v => v.videoId === current.videoId)
    if (currentIndex < currentList.length - 1) {
      const newPosition = isRepeating ? currentIndex : currentIndex + 1
      dispatch('play', currentList[newPosition])
    } else if (nextList.length) {
      currentList.push(nextList[0])
      nextList.shift()
      await commit('setCurrentAndNextLists', { currentList, nextList })
      dispatch('play', currentList[currentList.length - 1])
    }
  },
  shufflePlayList: async ({ state, dispatch }) => {
    const { currentList, nextList } = state
    const finalList = shufflePlaylistMirror([
      ...currentList,
      ...nextList
    ])
    const currentIndex = finalList.findIndex(v => v.videoId === state.current?.videoId)
    if (currentIndex >= 0) {
      moveArrayItem(finalList, currentIndex, 0)
    }

    await dispatch('updateLists', { currentList: finalList, nextList: [] })
  },
  toggleRepeat: async ({ state, commit }) => {
    await commit('setIsRepeating', !state.isRepeating)
  },
  updateExtensionVersion: async ({ commit }, version) => {
    await commit('setExtensionVersion', version)
  },
  addListAtEnd: async ({ commit, state }, { videoListNew, index }) => {
    const newCurrentList = addListOnListCurrent(videoListNew, state.currentList, state.current, index)
    await commit('setCurrentList', newCurrentList)
  },
  getVideoIndexListCurrent (action) {
    // TODO: Edward: Esta función debería ser un action de vuex porque no hace ningun cambio al estado (commit)
    const videoId = localStorage.currentVideoId
    const currentListJSON = this.$store.state.playList.currentList
    let currentList = []
    if (currentListJSON !== '') {
      currentList = JSON.parse(currentListJSON)
    }
    let index = 0
    if (currentList.length) {
      if (action === 'listNext') {
        index = currentList.findIndex(item => item.videoId === videoId)
        return index + 1
      } else {
        index = currentList.length + 1
        return index
      }
    } else {
      return 0
    }
  }
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
