import { format } from 'date-fns'

export const isPremiumActive = user => {
  // return false
  // user && user.planName === 'premium' && new Date(user.planEnd) > new Date()

  if (!user || !user.planName) return false
  const today = new Date(format(new Date(), 'yyyy-MM-dd') + 'T00:00:00')
  const planEndDate = user.planEnd && new Date(user.planEnd)
  return planEndDate >= today
}
