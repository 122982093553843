export function encode (str) {
  try {
    return window.btoa(unescape(encodeURIComponent(str)))
  } catch (error) {
    return null
  }
}

export function decode (str) {
  try {
    return decodeURIComponent(escape(window.atob(str)))
  } catch (error) {
    return null
  }
}
