import notificationsService from '@/services/notifications.service'

const state = () => ({
  notifications: [],
  isLoading: false,
  haveNew: false
})

const mutations = {
  setNotifications: (state, notifications) => {
    state.notifications = notifications
  },
  setIsLoading: (state, isLoading) => {
    state.isLoading = isLoading
  },
  setHaveNew: (state, haveNew) => {
    state.haveNew = haveNew
  }
}

const actions = {
  load: async ({ commit, state }, businessId) => {
    await commit('setIsLoading', true)
    const result = await notificationsService.get()
    if (result) {
      const newNotifications = result
      const haveNew = newNotifications.some(notification => !notification.readed)
      await commit('setNotifications', result)
      if (haveNew && !state.haveNew) {
        await commit('setHaveNew', haveNew)
      }
    }
    await commit('setIsLoading', false)
  }
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
