import debounce from '@/helpers/debounce'
import broadcastService from '@/services/broadcast.service'
import { onBroadcastMessageReceived, onPlayerStateChanged } from '@/services/global-events.service'

const poolingTime = 8000
// TODO: remove pooling timeout when if is necessary
// eslint-disable-next-line no-unused-vars
let poolingTimeOut = null
let broadcastNotifierDebounced = null

const cleanVideoModel = ({ videoId, thumbnailDefault, title }) => ({ videoId, thumbnailDefault, title })
const cleanListModel = list => list.map(video => cleanVideoModel(video))

const updateBroadcast = async (rootState) => {
  const { myBusiness, playList } = rootState
  const businessId = myBusiness.business.id
  if (businessId) {
    const { broadcastId } = rootState.broadcast
    let body = { businessId, enabled: !!broadcastId, broadcastId }
    if (broadcastId) {
      const { current, currentList, nextList, playerStatus } = playList
      const nextBroadcastList = getNextNVideos(current, currentList, nextList)
      body = {
        ...body,
        playerStatus,
        current: cleanVideoModel(current),
        nextList: cleanListModel(nextBroadcastList)
      }
    }
    await broadcastService.put(body)
  }
}

const checkBroadcastMessage = async (businessId) => {
  const result = await broadcastService.get(businessId)
  if (result) {
    onBroadcastMessageReceived.invoke(result)
  }
  poolingTimeOut = setTimeout(() => {
    checkBroadcastMessage(businessId)
  }, poolingTime)
}

const state = () => ({
  enabled: localStorage.broadcastEnabled === 'true',
  current: null,
  nextList: null,
  broadcastId: localStorage.broadcastId
})

const mutations = {
  setPlayerState: (state, playerState) => {
    state.enabled = playerState.enabled
    state.current = playerState.current
    state.nextList = playerState.nextList
  },
  setEnabled: (state, enabled) => {
    state.enabled = enabled
    localStorage.broadcastEnabled = enabled
  },
  setBroadcastId: (state, id) => {
    state.broadcastId = id
    if (id) {
      localStorage.broadcastId = id
    } else {
      localStorage.removeItem('broadcastId')
    }
  }
}

const getNextNVideos = (current, currentList, nextList) => {
  const nextCount = 5
  const index = currentList.findIndex(video => video.videoId === current.videoId)
  const nextBroadcastList = []
  for (let i = index + 1; i < index + nextCount + 1; i++) {
    if (!currentList[i]) break
    nextBroadcastList.push(currentList[i])
  }
  if (nextBroadcastList.length < nextCount) {
    for (let i = 0; i < nextList.length; i++) {
      if (!nextList[i] || nextBroadcastList.length === nextCount) break
      nextBroadcastList.push(nextList[i])
    }
  }
  return nextBroadcastList
}

const actions = {
  toggleBroadcastEnabled: async ({ commit, dispatch, state, rootState }) => {
    const businessId = rootState.myBusiness.business.id
    const isEnabled = !state.enabled
    if (businessId) {
      if (isEnabled) {
        const result = await broadcastService.start(businessId)
        if (result) {
          await commit('setBroadcastId', result.broadcastId)
        }
      } else if (state.broadcastId) {
        await broadcastService.delete(businessId, { broadcastId: state.broadcastId })
        await commit('setBroadcastId', null)
      }
      await commit('setEnabled', isEnabled)
      if (isEnabled) {
        await dispatch('startBroadcastNotifier')
      } else {
        await dispatch('stopBroadcastNotifier')
      }
      await dispatch('sendUpdateBroadcast')
    }
  },
  sendUpdateBroadcast: async ({ rootState }) => {
    updateBroadcast(rootState)
  },
  startBroadcastNotifier: async ({ dispatch }) => {
    broadcastNotifierDebounced = debounce(() => {
      dispatch('sendUpdateBroadcast')
    }, 3000)
    onPlayerStateChanged.add(broadcastNotifierDebounced)
  },
  stopBroadcastNotifier: async () => {
    if (broadcastNotifierDebounced) {
      onPlayerStateChanged.remove(broadcastNotifierDebounced)
      broadcastNotifierDebounced = null
    }
  },
  toggleBroadcastListener: async ({ commit, rootState }) => {
    checkBroadcastMessage(rootState.appBusiness.business.id)

    onBroadcastMessageReceived.add(message => {
      commit('setPlayerState', message)
    })
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
